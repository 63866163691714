import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Team from "../components/Team"

const TeamPage = () => (
  <div>
    <Helmet>
      <title>Endorfina</title>
      <meta
      name="Endorfina, building happiness, architecture, Romania, Cluj, Cluj-Napoca, arhitectura, urbanism, design, design interior"
      content="architecture, Romania, Cluj, Cluj-Napoca, arhitectura, urbanism, design, design interior"
      />
    </Helmet>
    <Navbar />
    <Team />
    <Footer />
  </div>
)

export default TeamPage
