import React from "react"

import PozaAlida from "../images/echipa/alida.jpg"
import PozaAlina from "../images/echipa/alina.jpg"
import PozaAndreea from "../images/echipa/andreea.jpg"
import PozaGeorge from "../images/echipa/george.jpg"
import PozaHorea from "../images/echipa/horea.jpg"
import PozaRaluca from "../images/echipa/raluca.jpg"
import PozaVlad from "../images/echipa/default.jpg"

const Team = () => (
    <>
    <div class="container pt-5 my-5">
        <section class="p-md-3 mx-md-5 text-center text-lg-left">
            <h2 class="text-center mx-auto font-weight-bold mb-4 pb-2">Echipa</h2>
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div class="p-3">
                        <div class="avatar w-100 white d-flex justify-content-center align-items-center">
                            <img
                            src= {PozaAlida}
                            class="img-fluid rounded-circle z-depth-1"
                            alt="PozaAlida"
                            />
                        </div>
                        </div>
                        <div class="text-center">
                            <h6 class="font-weight-bold pt-2">Alida VIȘAN</h6>
                            <p class="text-muted">
                                <small>
                                    founder, arhitect, urbanist
                                </small>
                                <br/>
                                <small>
                                    alida@endorfinaconcept.ro
                                </small>
                            </p>
                        </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div class="p-3">
                        <div class="avatar w-100 white d-flex justify-content-center align-items-center">
                            <img
                            src={PozaAlina}
                            class="img-fluid rounded-circle z-depth-1"
                            alt="PozaAlina"
                            />
                        </div>
                    </div>
                        <div class="text-center">
                            <h6 class="font-weight-bold pt-2">Alina MOCEANU</h6>
                            <p class="text-muted">
                                <small>
                                    office manager
                                </small>
                                <br/>
                                <small>
                                    alina@endorfinaconcept.ro
                                </small>
                            </p>
                        </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div class="p-3">
                        <div class="avatar w-100 white d-flex justify-content-center align-items-center">
                            <img
                            src={PozaAndreea}
                            alt="PozaAndreea"
                            class="img-fluid rounded-circle z-depth-1"
                            />
                        </div>
                    </div>
                        <div class="text-center">
                            <h6 class="font-weight-bold pt-2">Andreea BODEA</h6>
                            <p class="text-muted">
                                <small>
                                    arhitect
                                </small>
                                <br/>
                                <small>
                                    andreea@endorfinaconcept.ro
                                </small>
                            </p>
                        </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div class="p-3">
                        <div class="avatar w-100 white d-flex justify-content-center align-items-center">
                            <img
                            src={PozaGeorge}
                            class="img-fluid rounded-circle z-depth-1"
                            alt="PozaGeorge"
                            />
                        </div>
                        </div>
                        <div class="text-center">
                            <h6 class="font-weight-bold pt-2">George BLAGA</h6>
                            <p class="text-muted">
                                <small>
                                    arhitect
                                </small>
                                <br/>
                                <small>
                                    george@endorfinaconcept.ro
                                </small>
                            </p>
                        </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div class="p-3">
                        <div class="avatar w-100 white d-flex justify-content-center align-items-center">
                            <img
                            src={PozaHorea}
                            class="img-fluid rounded-circle z-depth-1"
                            alt="PozaHorea"
                            />
                        </div>
                        </div>
                        <div class="text-center">
                            <h6 class="font-weight-bold pt-2">Horea RUSU</h6>
                            <p class="text-muted">
                                <small>
                                    arhitect
                                </small>
                                <br/>
                                <small>
                                    horea@endorfinaconcept.ro
                                </small>
                            </p>
                        </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div class="p-3">
                        <div class="avatar w-100 white d-flex justify-content-center align-items-center">
                            <img
                            src={PozaRaluca}
                            alt="PozaRaluca"
                            class="img-fluid rounded-circle z-depth-1"
                            />
                        </div>
                        </div>
                        <div class="text-center">
                            <h6 class="font-weight-bold pt-2">Raluca HĂRȘAN</h6>
                            <p class="text-muted">
                                <small>
                                    arhitect
                                </small>
                                <br/>
                                <small>
                                    raluca@endorfinaconcept.ro
                                </small>
                            </p>
                        </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div class="p-3">
                        <div class="avatar w-100 white d-flex justify-content-center align-items-center">
                            <img
                            src={PozaVlad}
                            alt="PozaVlad"
                            class="img-fluid rounded-circle z-depth-1"
                            />
                        </div>
                        </div>
                        <div class="text-center">
                            <h6 class="font-weight-bold pt-2">Vlad RAȚ</h6>
                            <p class="text-muted">
                                <small>
                                    arhitect, urbanist
                                </small>
                                <br/>
                                <small>
                                    vlad@endorfinaconcept.ro
                                </small>
                            </p>
                        </div>
                </div>
            </div>
        </section>
    </div>

    </>
    )

export default Team